import React, { useEffect, useRef, useState } from 'react';
import { BufferGeometry, Material, Mesh, NormalBufferAttributes, Object3DEventMap, Vector3 } from 'three';
import useKeyPress from './KeyPress';
import { BallCollider, RapierRigidBody, RigidBody, useRevoluteJoint } from '@react-three/rapier';
import { useFrame } from '@react-three/fiber';
import { Socket, io } from 'socket.io-client';


interface PhysicsBowlerProps {
    origin: Vector3;
}

interface BowlingArmProps {
    origin: Vector3;
    bowlingArmRef: RapierRigidBody | null;
    setBallPosition: any;
    setBallVelocity: any;
}

const BowlingArm: React.FC<BowlingArmProps> = ({ origin, bowlingArmRef, setBallPosition, setBallVelocity }) => {
    const ref = useRef()

    const anchor = useRef<RapierRigidBody | null>(bowlingArmRef);

    // useEffect(() => {
    //     const gui = new GUI()
    //     if (refMesh === null) {
    //         return
    //     }
    //     if (refMesh.current === null) {
    //         return
    //     }
    //     gui.add(refMesh.current.position, 'x', 0, 100)
    //     gui.add(refMesh.current.position, 'z', 0, 100)
    //     return () => {
    //         gui.destroy()
    //     }
    // }, [bowlingArmRef])

    const ball = useRef<RapierRigidBody | null>(null);
    const [numTimesRun, setNumTimesRun] = useState(0)
    const [numTimesRun2, setNumTimesRun2] = useState(0)
    const [numTimesRun3, setNumTimesRun3] = useState(0)
    // const joint = useRopeJoint(anchor, ball, [[0, 0, 0], [0, 0, 0], 0.75]);
    const joint = useRevoluteJoint(anchor, ball, [
        // Position of the joint in bodyA's local space
        [0, 0, 0],
        // Position of the joint in bodyB's local space
        [0, -1.25, 0],
        // Axis of the joint, expressed in the local-space of
        // the rigid-bodies it is attached to. Cannot be [0,0,0].
        [-1, 0, 0],
        [-100, 100]
    ]);

    useFrame(() => {
        if (joint.current) {
            joint.current.configureMotorVelocity(45, 2);
        }
    });

    const refMesh = useRef<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>(null)

    const isUpPressed = useKeyPress(['ArrowUp']);
    useEffect(() => {
        if (numTimesRun > 0 && isUpPressed) {
            // console.error(ball.current?.translation())
            // console.error(ball.current?.angvel())
            // console.error(ball.current?.linvel())
        }
        setNumTimesRun(numTimesRun + 1)
    }, [isUpPressed])

    const isEnterPressed = useKeyPress(['Enter']);
    useEffect(() => {
        if (numTimesRun2 > 0 && isEnterPressed) {
            ball.current?.applyImpulse(new Vector3(2, 0, 0), true)
            // ball.current?.applyTorqueImpulse(new Vector3(2, 0, 0), true)
        }
        setNumTimesRun2(numTimesRun2 + 1)
    }, [isEnterPressed])

    const isSpacePressed = useKeyPress([' ']);
    useEffect(() => {
        if (numTimesRun3 > 0 && isSpacePressed) {
            if (ball.current) {
                const linvel = ball.current.linvel() as Vector3
                setBallVelocity(linvel)
                const position = ball.current.translation() as Vector3
                setBallPosition(position)
            }
        }
        setNumTimesRun3(numTimesRun3 + 1)
    }, [isSpacePressed])

    const isBackspacePressed = useKeyPress(['Backspace']);
    useEffect(() => {
        ball.current?.setTranslation(origin, true)
        ball.current?.setLinvel(new Vector3(0, 0, 0), true)
        ball.current?.setAngvel(new Vector3(0, 0, 0), true)
        // ball.current?.applyImpulse(new Vector3(50, 0, 0), true)
    }, [isBackspacePressed])

    return (
        // <group position={new Vector3(0, 0.83, -11.5)}>
        // <group position={new Vector3(0, -2, -11.5)}>
        <group>
            <RigidBody ref={anchor}></RigidBody>
            <RigidBody ref={ball} position={[0, -2, 0]}>
                <mesh ref={refMesh} scale={0.75 / 2} >
                    <sphereGeometry></sphereGeometry>
                    <meshStandardMaterial color={0xBC00FF} />
                </mesh>
            </RigidBody>
        </group>
    );
}

const PhysicsBowler: React.FC<PhysicsBowlerProps> = ({ origin }) => {
    const bowlingArmRef = useRef<RapierRigidBody | null>(null);

    const [isFlying, setIsFlying] = useState(false)
    const [numTimesPressEnter, setNumTimesPressEnter] = useState(0)
    const [ballPosition, setBallPosition] = useState<Vector3 | null>(null);
    const [ballVelocity, setBallVelocity] = useState<Vector3 | null>(null);
    const flyingBallBody = useRef<RapierRigidBody | null>(null);
    const flyingBallMesh = useRef<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>(null)
    const socket = useRef<Socket>()

    useEffect(() => {
        // socket.current = io('http://localhost:3003')
        // socket.current = io('https://bowlinf-ws.fly.dev:3003')
        socket.current = io('https://bowlinf-ws.fly.dev')

        // document.addEventListener('keydown', handleKeyPress);

        socket.current.on('bowl', (eventData: any) => {
            console.error("Received emitted bowl from server")
            console.error(eventData.ballData)
            const ballPosition = eventData.ballData.position
            const ballVelocity = eventData.ballData.velocity
            flyingBallBody.current?.setTranslation(ballPosition as Vector3, true)
            flyingBallBody.current?.setLinvel(ballVelocity as Vector3, true)
            setIsFlying(true)
        })

        return () => {
            // document.removeEventListener('keydown', handleKeyPress);
            socket.current?.off('bowl')
        }
    }, [])


    const isSpacePressed = useKeyPress([' ']);
    useEffect(() => {
        if (ballPosition === null || ballVelocity === null) {
            return
        }
        console.error("client sends bowl")
        socket.current?.emit('bowl', { ballData: { position: ballPosition, velocity: ballVelocity } })
        // flyingBallBody.current?.setTranslation(ballPosition as Vector3, true)
        // flyingBallBody.current?.setLinvel(ballVelocity as Vector3, true)
        // setIsFlying(true)
    }, [isSpacePressed])

    const isBackspacePressed = useKeyPress(['Backspace']);
    useEffect(() => {
        setIsFlying(false)
    }, [isBackspacePressed])

    return (
        <group position={origin}>
            {!isFlying && (<BowlingArm bowlingArmRef={bowlingArmRef.current} setBallPosition={setBallPosition} setBallVelocity={setBallVelocity} origin={origin}></BowlingArm>)}
            <RigidBody ref={flyingBallBody} position={[0, -50, 0]}>
                <mesh ref={flyingBallMesh} scale={0.75 / 2} >
                    <sphereGeometry></sphereGeometry>
                    <meshStandardMaterial color={"red"} />
                    <BallCollider position={[0, 0, 0]} args={[0.35]}></BallCollider>
                </mesh>
            </RigidBody>
        </group>
    );
};
export default PhysicsBowler;
