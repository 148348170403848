import React from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Vector3 } from 'three';
import { Physics } from '@react-three/rapier';
import PhysicsPins from './PhysicsPins';
import PhysicsAlley from './PhysicsAlley';
import PhysicsBowler from './PhysicsBowler';

interface ActiveBowlingProps {
}

const USE_TOPDOWN_CAM = false;
const USE_SIDE_CAM = false;
const USE_VIEW_CAM = true;
const USE_ORBIT_CAM = false;

const ActiveBowling: React.FC<ActiveBowlingProps> = ({ }) => {

    return (
        <Physics>
            {USE_SIDE_CAM && <PerspectiveCamera makeDefault position={[-45, 0.01, 0]} onUpdate={(self) => self.lookAt(new Vector3(0, 0, 0))} />}
            {USE_TOPDOWN_CAM && <PerspectiveCamera makeDefault position={[0, 60, 0]} onUpdate={(self) => self.lookAt(new Vector3(0, 0, 0))} />}
            {USE_VIEW_CAM && <PerspectiveCamera makeDefault position={[7.36, 6.23, -18.47]} fov={60} onUpdate={(self) => self.lookAt(new Vector3(-5.93, 0.567, 3.61))} />}
            {USE_ORBIT_CAM && <OrbitControls />}
            <PhysicsBowler origin={new Vector3(0, 2.25, -11.5)} />
            {/* <PhysicsBall scale={0.75 / 2} origin={new Vector3(0, 0.83, -11.5)} /> */}
            <PhysicsPins origin={new Vector3(0, 0, 9.5)} />
            {/* <Html position={[2, 5, 0]}>Your Turn</Html> */}
            <PhysicsAlley origin={new Vector3(0, -0.125, 0)} width={5} height={0.25} depth={25} />
            {/* <Motor /> */}
        </Physics>
    );
};

export default ActiveBowling;