import React, { useEffect, useRef, useState } from 'react';
import ActiveBowling from './ActiveBowling';
import WatchingBowling from './WatchingBowling';
import { Html } from '@react-three/drei';
import { Vector3 } from 'three';
import MultiplayerStatus from './MultiplayerStatus';
import ControlsInfo from './ControlsInfo';
import { io, Socket } from 'socket.io-client';

interface GameProps {
    lobbyName: string;
}

const Game: React.FC<GameProps> = ({ lobbyName }) => {
    // const [isClientPlayersTurn, setIsClientPlayersTurn] = useState(true);
    const [playerNames, setPlayerNames] = useState<string[]>([])
    const socket = useRef<Socket>()

    useEffect(() => {
        // socket.current = io('http://localhost:3003')
        socket.current = io('https://bowlinf-ws.fly.dev:3003')
        // document.addEventListener('keydown', handleKeyPress);

        socket.current.on('gameData', (gameData: any) => {
            // ref.current?.position.set(gameData.ball.p.x, gameData.ball.p.y, gameData.ball.p.z)
            setPlayerNames(Object.keys(gameData.players))
        })

        return () => {
            // document.removeEventListener('keydown', handleKeyPress);
            socket.current?.off('gameData')
        }
    }, [])

    return (
        <>
            <directionalLight
                position={[-5, 20, -30]}
                castShadow
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                shadow-camera-left={-20}
                shadow-camera-right={40}
                shadow-camera-top={30}
            />
            <Html style={{ whiteSpace: 'nowrap' }} position={new Vector3(0, 11.5, 0)}>In {lobbyName}</Html>
            <MultiplayerStatus playerNames={playerNames} />
            <ControlsInfo />
            <ActiveBowling />
            {/* {!isClientPlayersTurn && <WatchingBowling />} */}
        </>
    );
};

export default Game