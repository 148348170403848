import React, { Suspense, useState } from 'react';
import { Canvas } from "@react-three/fiber";
import Game from './Game';
import { PerformanceMonitor, Stats } from '@react-three/drei';
import { MouseEvent } from 'react';

function Loading() {
    return <h1>Loading...</h1>
}


const App = () => {
    const [dpr, setDpr] = useState(0.5)

    const handleCanvasContextMenu = (event: MouseEvent) => {
        event.preventDefault();
    };

    return (
        <>
            <Suspense fallback={<Loading />}>
                <Canvas
                    shadows dpr={dpr}
                    onContextMenu={handleCanvasContextMenu}
                >
                    <PerformanceMonitor onIncline={() => setDpr(1)} onDecline={() => setDpr(0.25)}>
                        <Stats />
                        <Game lobbyName={"Lobby Name"} />
                    </PerformanceMonitor>
                </Canvas>
            </Suspense>
        </>
    );
};
export default App;
