import React, { useEffect, useState } from 'react';
import { Html, PerspectiveCamera } from '@react-three/drei';
import { Vector3 } from 'three';
import useKeyPress from './KeyPress';

interface MultiplayerStatusProps {
    playerNames: string[];
}

const MultiplayerStatus: React.FC<MultiplayerStatusProps> = ({ playerNames }) => {
    const [posVec, setPosVec] = useState(new Vector3(7, 10, 4))

    // const up = useKeyPress(['ArrowUp']);
    // const down = useKeyPress(['ArrowDown']);
    // const left = useKeyPress(['ArrowLeft']);
    // const right = useKeyPress(['ArrowRight']);
    // const one = useKeyPress(['1']);
    // const two = useKeyPress(['2']);
    // useEffect(() => {
    //     if (up) {
    //         setPosVec(new Vector3(posVec.x, posVec.y, posVec.z + 1))
    //     }
    //     if (down) {
    //         setPosVec(new Vector3(posVec.x, posVec.y, posVec.z - 1))
    //     }
    //     if (left) {
    //         setPosVec(new Vector3(posVec.x + 1, posVec.y, posVec.z))
    //     }
    //     if (right) {
    //         setPosVec(new Vector3(posVec.x - 1, posVec.y, posVec.z))
    //     }
    //     if (one) {
    //         setPosVec(new Vector3(posVec.x, posVec.y + 1, posVec.z))
    //     }
    //     if (two) {
    //         setPosVec(new Vector3(posVec.x, posVec.y - 1, posVec.z))
    //     }
    //     console.error(posVec.x, posVec.y, posVec.z)
    // }, [up, down, left, right, one, two])

    return (
        <>
            {playerNames.map((playerName, index) => {
                return (
                    <Html style={{ whiteSpace: 'nowrap' }} key={index} position={new Vector3(posVec.x, posVec.y - index * 0.5, posVec.z)}>{playerName}</Html>
                )
            })}
        </>
    );
};

export default MultiplayerStatus;