import { CuboidCollider } from "@react-three/rapier";
import React from "react";
import { DoubleSide, Vector3 } from "three";

interface PhysicsAlleyProps {
    origin: Vector3;
    width: number;
    height: number;
    depth: number;
}

const PhysicsAlley: React.FC<PhysicsAlleyProps> = ({ origin, width, height, depth }) => {
    return (
        <mesh position={origin} receiveShadow>
            <boxGeometry args={[width, height, depth]}></boxGeometry>
            <meshStandardMaterial color={0xF5CC8C} side={DoubleSide}></meshStandardMaterial>
            <CuboidCollider position={[origin.x, origin.y, origin.z]} args={[width, height, depth]} />
        </mesh>
    );
};
export default PhysicsAlley;