import { useEffect, useState } from 'react';

const useKeyPress = (targetKeys: string[]) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = ({ key }: KeyboardEvent) => {
        // console.error(key)
        if (targetKeys.includes(key)) {
            setKeyPressed(true);
        }
    };

    const upHandler = ({ key }: KeyboardEvent) => {
        if (targetKeys.includes(key)) {
            setKeyPressed(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKeys]);

    return keyPressed;
};

export default useKeyPress;
