import React from 'react';
import { Mesh } from 'three';
import { Vector3 } from '@react-three/fiber';
import { useGLTF } from "@react-three/drei";
import { RigidBody } from '@react-three/rapier';

interface PhysicsModelPinProps {
    origin: Vector3;
}

export const useModelPin = () => {
    // @ts-ignore
    return useGLTF(
        new URL("./models/BowlingPin_bowlingpin.glb", import.meta.url).toString()
    ) as {
        nodes: {
            bowlingpin: Mesh;
        };
    };
};


const PhysicsModelPin: React.FC<PhysicsModelPinProps> = ({ origin }) => {
    const { nodes } = useModelPin()

    return (
        <RigidBody>
            <mesh
                position={origin}
                castShadow receiveShadow
                scale={0.25}
                geometry={nodes.bowlingpin.geometry}
                material={nodes.bowlingpin.material}
            />
        </RigidBody>
    );
};
export default PhysicsModelPin;
