import React from 'react';
import { Vector3 } from 'three';
import PhysicsModelPin from './PhysicsModelPin';

interface PhysicsPinsProps {
    origin: Vector3
}

const PhysicsPins: React.FC<PhysicsPinsProps> = ({ origin }) => {

    return (
        <group position={origin}>
            <PhysicsModelPin origin={new Vector3(0, 0, 0)} />
            <PhysicsModelPin origin={new Vector3(-0.25, 0, 0.5)} />
            <PhysicsModelPin origin={new Vector3(0.25, 0, 0.5)} />
            <PhysicsModelPin origin={new Vector3(-0.5, 0, 1)} />
            <PhysicsModelPin origin={new Vector3(0, 0, 1)} />
            <PhysicsModelPin origin={new Vector3(0.5, 0, 1)} />
            <PhysicsModelPin origin={new Vector3(-0.75, 0, 1.5)} />
            <PhysicsModelPin origin={new Vector3(-0.25, 0, 1.5)} />
            <PhysicsModelPin origin={new Vector3(0.25, 0, 1.5)} />
            <PhysicsModelPin origin={new Vector3(0.75, 0, 1.5)} />
        </group>
    );
};

export default PhysicsPins;