import React, { useEffect, useState } from 'react';
import { Html } from '@react-three/drei';
import { Vector3 } from 'three';
// import useKeyPress from './KeyPress';

interface ControlsInfoProps {
    instructionLines?: string[];
}

const ControlsInfo: React.FC<ControlsInfoProps> = ({ instructionLines }) => {
    const [posVec, setPosVec] = useState(new Vector3(-32, 17, 17))

    // const up = useKeyPress(['ArrowUp']);
    // const down = useKeyPress(['ArrowDown']);
    // const left = useKeyPress(['ArrowLeft']);
    // const right = useKeyPress(['ArrowRight']);
    // const one = useKeyPress(['1']);
    // const two = useKeyPress(['2']);
    // useEffect(() => {
    //     if (up) {
    //         setPosVec(new Vector3(posVec.x, posVec.y, posVec.z + 1))
    //     }
    //     if (down) {
    //         setPosVec(new Vector3(posVec.x, posVec.y, posVec.z - 1))
    //     }
    //     if (left) {
    //         setPosVec(new Vector3(posVec.x + 1, posVec.y, posVec.z))
    //     }
    //     if (right) {
    //         setPosVec(new Vector3(posVec.x - 1, posVec.y, posVec.z))
    //     }
    //     if (one) {
    //         setPosVec(new Vector3(posVec.x, posVec.y + 1, posVec.z))
    //     }
    //     if (two) {
    //         setPosVec(new Vector3(posVec.x, posVec.y - 1, posVec.z))
    //     }
    //     console.error(posVec.x, posVec.y, posVec.z)
    // }, [up, down, left, right, one, two])

    const step = 1.2;
    return (
        <>
            <Html style={{ whiteSpace: 'nowrap' }} position={new Vector3(posVec.x, posVec.y, posVec.z)}>Space: Throw Ball</Html>
            <Html style={{ whiteSpace: 'nowrap' }} position={new Vector3(posVec.x, posVec.y - step * 1, posVec.z)}>Backspace: Reset Ball</Html>
            <Html style={{ whiteSpace: 'nowrap' }} position={new Vector3(posVec.x, posVec.y - step * 2, posVec.z)}>N: Next Player</Html>
            <Html style={{ whiteSpace: 'nowrap' }} position={new Vector3(posVec.x, posVec.y - step * 3, posVec.z)}>R: Reset Pins</Html>
        </>
    );
};

export default ControlsInfo;